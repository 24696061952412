<template>
    <div class="all-section-area">
        <MainHeader />
        <main class="main-content-wrap">
            <PageTitle mainTitle="Catálogo de cursos" pageTitle="Catálogo de cursos" />
            <CatalogoCursos />
            <FooterArea />
        </main>
    </div>
</template>

<script>
import MainHeader from '../Layouts/MainHeader'
import PageTitle from '../Common/PageTitle'
import CatalogoCursos from '../Cursos/Catalogo'
import FooterArea from '../Layouts/FooterArea'

export default {
    name: 'CursosPage',
    components: {
        MainHeader,
        PageTitle,
        CatalogoCursos,
        FooterArea,
    },
    mounted () {
        document.body.classList.add('body-bg-f8faff')
    },
}
</script>