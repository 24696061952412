<template>
    <div class="all-section-area">
        <MainHeaderGuest />
        <main class="main-content-wrap">
            <h3>Catálogo de cursos</h3>
            <CatalogoCursos />
            <FooterAreaGuest />
        </main>
    </div>
</template>

<script>
import MainHeaderGuest from '../Layouts/MainHeaderGuest'
import CatalogoCursos from '../Cursos/CatalogoGuest'
import FooterAreaGuest from '../Layouts/FooterAreaGuest'

export default {
    name: 'CursosGuestPage',
    components: {
        MainHeaderGuest,
        CatalogoCursos,
        FooterAreaGuest,
    },
    mounted () {
        document.body.classList.add('body-bg-f8faff')
    },
}
</script>

<style lang="scss">
.main-content-wrap {
    padding-top: 80px !important;
    padding-left: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
}
</style>