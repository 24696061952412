<template>
    <div class="all-section-area">
        <MainHeader />
        <main class="main-content-wrap">
            <PageTitle :mainTitle=title :pageTitle=title />
            <AdminEditorCuestionario />
            <FooterArea />
        </main>
    </div>
</template>

<script>
import MainHeader from '../Layouts/MainHeader'
import PageTitle from '../Common/PageTitle'
import AdminEditorCuestionario from '../Admin/EditorCuestionario'
import FooterArea from '../Layouts/FooterArea'

export default {
    name: 'AdminEditorCuestionarioPage',
    components: {
        MainHeader,
        PageTitle,
        AdminEditorCuestionario,
        FooterArea,
    },
    data() {
        return {
            title: '',
        }
    },
    mounted() {
        document.body.classList.add('body-bg-f8faff')
        if (this.$route.query.id == 0) {
            this.title = 'Agregar Cuestionario'
        } else {
            this.title = 'Editar Cuestionario'
        }
    }
}
</script>