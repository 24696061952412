<template>
    <div class="widgets-area">
        <div class="container-fluid">
            <div class="card-box-style">
                <div class="row justify-content-center">
                    <h4>Cursos</h4>
                    <div class="col-lg-4 col-sm-6" v-if="cursos.length == 0">
                        <router-link to="catalogo" class="btn btn-primary">
                            <i class="bx bx-book-content"></i> Aún no te haz inscrito en un curso. Haz click aquí para ver el catálogo e inscribirte
                        </router-link>
                    </div>
                    <div class="col-lg-4 col-sm-6" v-for="(curso, index) in cursos" :key="index">
                        <div class="card card-box mb-24">
                            <div class="card-body">
                                <h5 class="card-title">{{curso.nombre_curso}}</h5>
                                <progress max="100" :value="(curso.avance / curso.lecturas) * 100" style="width: 100%;">
                                    {{ (curso.avance / curso.lecturas) * 100 }}%</progress>
                                <p v-if="(curso.avance / curso.lecturas) * 100 > 0" class="card-statics">Adelante, haz
                                    completado el <span class="badge" style="background-color: green;">{{ (curso.avance
                                        / curso.lecturas) * 100
                                        }}%</span> del curso</p>
                                <p class="card-statics"><span class="badge">{{ curso.modulos }}</span> modulos / <span
                                        class="badge">{{ curso.lecturas }}</span> lecturas</p>
                                <router-link v-if="curso.avance > 0" :to="link(curso.id_curso)" class="btn btn-success">
                                    <i class="bx bx-plus"></i> Continuar
                                </router-link>
                                <router-link v-if="curso.avance == 0" :to="link(curso.id_curso)"
                                    class="btn btn-success">
                                    <i class="bx bx-plus"></i> Iniciar
                                </router-link>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row justify-content-center">

                    <div class="col-lg-3 col-sm-6" v-for="(curso, index) in cursosRecomendado" :key="index">
                        <div class="card card-box border-primary mb-24">
                            <div class="card-header">{{ curso.nombre_curso }}</div>
                            <div class="card-body">
                                <h5 class="card-title">{{ curso.descripcion_curso_corta }}</h5>
                                <p class="card-statics"><span class="badge">{{ curso.modulos }}</span> modulos / <span
                                        class="badge">{{ curso.lecturas }}</span> lecturas</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
//import moment from 'moment-timezone'

export default {
    name: 'CursosContent',
    data() {
        return {
            cursos: {},
            cursoActual: {},
            cursosRecomendado: {},
        }
    },
    mounted() {
        let loaderCursos = this.$loading.show({ canCancel: false })
        let loaderCursoActual = this.$loading.show({ canCancel: false })
        axios
            .get(this.$backendUrl + '/me/cursos', { headers: { token: localStorage.userToken } })
            .then(response => {
                this.cursos = response.data.data
                loaderCursos.hide()
            }, error => {
                console.log(error.response.data.description)
                loaderCursos.hide()
            })
        axios
            .get(this.$backendUrl + '/me/cursos/actual', { headers: { token: localStorage.userToken } })
            .then(response => {
                this.cursoActual = response.data
                this.cursoActual.id_url = btoa(this.cursoActual.id_curso)
                loaderCursoActual.hide()
            }, error => {
                console.log(error.response.data.description)
                loaderCursoActual.hide()
            })
    },
    methods: {
        link: function (id) {
            return '/cursos/visor?id=' + btoa(id)
        }
    },
}
</script>

<style lang="scss">
.card-box {
    h5 {
        font-weight: 500;
        font-size: 20px;
    }
}

.card-statics {
    .badge {
        background-color: #1765fd;
    }
    font-size: 12px;
}
</style>