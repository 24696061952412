<template>
    <div class="row">
        <h3>{{ cursoActual.nombre_curso }}</h3>
    </div>
    <div class="visor" style="width: 95%;">
        <div class="row">
            <div class="col-sm-12 col-md-8">
                <div class="row right-bar">
                    <h4 v-if="nombreLectura">{{ nombreModulo }} :: <span style="font-size: 0.8em;">{{ nombreLectura
                            }}</span></h4>
                    
                    <div v-if="tipo == 'video'">
                        <div v-if="nombreVideo !== null" class="col-sm-12">
                            <div v-html="nombreVideo"></div>
                            <!--<div style="position:relative;padding-top:56.25%;"><iframe src="https://iframe.mediadelivery.net/embed/328406/49d8a42c-81eb-49d3-8366-414ef715b180?autoplay=true&loop=false&muted=false&preload=true&responsive=true" loading="lazy" style="border:0;position:absolute;top:0;height:100%;width:100%;" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowfullscreen="true" id="bunny"></iframe></div>-->
                            <div v-if="contenidoVisor !== null" v-html="contenidoVisor"></div>
                        </div>
                    </div>
                    
                    <div v-if="tipo == 'html'">
                        <div v-if="contenidoVisor !== null" v-html="contenidoVisor"></div>
                    </div>

                    <div class="cuestionario" v-if="tipo == 'cuestionario'">
                        <h6>{{cuestionario.titulo}}</h6>
                        <div v-html="cuestionario.descripcion"></div>
                        <div class="pregunta" v-for="(pregunta, indexPregunta) in cuestionario.preguntas" :key="indexPregunta">
                            <div v-html="pregunta.pregunta"></div>
                            <div class="respuesta" v-for="(respuesta, indexRespuesta) in pregunta.detalle.opciones" :key="indexRespuesta">
                                {{ respuesta }}
                            </div>
                        </div>
                    </div>

                    <button v-if="nombreLectura" type="button" class="btn btn-success mb-1 me-1 btn-completar" @click="completarLectura()" :disabled="counting">
                        <vue-countdown v-if="counting" :time="120000" @end="onCountdownEnd" v-slot="{ minutes, seconds }">Pon atención al vídeo {{ minutes }}:{{ seconds }}</vue-countdown>
                        <span v-else>Marcar como completada</span>
                    </button>
                </div>
            </div>
            <div class="col-sm-12 col-md-4 temas">
                <div class="row">
                    <h6>Temas</h6>
                </div>

                <div class="accordion" id="accordionExample">
                    <div class="accordion-item" v-for="(modulo, indexModulo) in cursoActual.modulos" :key="indexModulo">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                :data-bs-target="idAcorddionTarget(indexModulo)" aria-expanded="true"
                                :aria-controls="idAcorddion(indexModulo)">
                                {{ modulo.nombre_modulo }}
                            </button>
                        </h2>
                        <div :id="idAcorddion(indexModulo)" class="accordion-collapse collapse show"
                            aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <div class="row" v-for="(lectura, indexLectura) in modulo.lecturas" :key="indexLectura"
                                    @click="verLectura(indexModulo, indexLectura)">
                                    <div class="col-sm-1">
                                        <div class="checkbox">
                                            <input class="form-check-input" type="checkbox" :checked="lectura.leida > 0"
                                                disabled>
                                        </div>
                                    </div>
                                    <div class="col-sm-11">
                                        <span>{{ lectura.nombre_lectura }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios'

export default {
    name: 'VisorCurso',
    data() {
        return {
            id_curso: 0,
            cursoActual: {},
            contenidoVisor: null,
            nombreVideo: null,
            nombreLectura: null,
            nombreModulo: null,
            pathVideo: null,
            tipo: '',
            lectura: {},
            lecturaActual: 0,
            indexModuloActual: 0,
            indexLecturaActual: 0,
            files: [],
            cuestionario: {},
            counting: true,
        }
    },
    mounted() {
        let loaderCursoActual = this.$loading.show({ canCancel: false })
        if (this.$route.query.id !== undefined) {
            this.id_curso = atob(this.$route.query.id)
        }
        axios
            .get(this.$backendUrl + '/me/curso?id=' + this.id_curso, { headers: { token: localStorage.userToken } })
            .then(response => {
                this.cursoActual = response.data.data
                loaderCursoActual.hide()
            }, error => {
                console.log(error.response.data.description)
                loaderCursoActual.hide()
            })
        axios
            .get(this.$backendUrl + '/me/curso/avance/?id=' + this.id_curso, { headers: { token: localStorage.userToken } })
            .then(response => {
                //loaderCursoActual.hide()
                console.log(response.data)
                if (response.data.data.lectura === null) {
                    this.lecturaActual = 0
                    /*setTimeout(function () {
                        this.verLectura(0, 0)
                    }, 1000)*/
                } else {
                    this.lecturaActual = response.data.data.lectura
                }
            }, error => {
                console.log(error.response.data.description)
                //loaderCursoActual.hide()
            })
    },
    methods: {
        startCountdown: function () {
            this.counting = true;
        },
        onCountdownEnd: function () {
            this.counting = false;
        },
        completarLectura() {
            this.$swal({ position: "top-end", toast: true, icon: "success", title: "Felicidades, haz completado esta lección!", showConfirmButton: false, timer: 3500 })
            let loaderCursoActual = this.$loading.show({ canCancel: false })
            this.lectura.leida = 1
            axios
                .post(this.$backendUrl + '/me/curso/avance', {
                    'id_curso': this.id_curso,
                    'id_curso_modulo_lectura': this.lectura.id_curso_modulo_lectura
                }, { headers: { token: localStorage.userToken } })
                .then(response => {
                    loaderCursoActual.hide()
                    console.log(response)
                }, error => {
                    loaderCursoActual.hide()
                    console.log(error.response.data.description)
                })
            if (this.lectura.cumple_logro == 1) {
                axios
                    .post(this.$backendUrl + '/me/info/logro', {
                        'id_curso_modulo_lectura': this.lectura.id_curso_modulo_lectura,
                        'texto_logro': this.lectura.texto_logro,
                    }, { headers: { token: localStorage.userToken } })
                    .then(response => {
                        console.log(response)
                        if (typeof response.data.data === 'object' && response.data.data !== null) {
                            this.$swal({title: "Nuevo logro!", text: this.lectura.texto_logro, 
                                padding: "3em",
                                color: "#0c63e4",
                                background: "#fff url(https://talencer.pro/wp-content/uploads/2024/10/logro_background.png)",
                                timerProgressBar: true,
                                timer: 3000,
                                backdrop: `
                                rgba(0,0,128,0.8)
                                url("https://talencer.pro/wp-content/uploads/2024/10/diamond.webp")
                                top
                                no-repeat
                                `
                            })
                        }
                    }, error => {
                        console.log(error.response.data.description)
                    })
            }
        },
        idAcorddion(id) {
            return 'modulo' + id;
        },
        idAcorddionTarget(id) {
            return '#modulo' + id;
        },
        /**
         * Has changed
         * @param  Object|undefined   newFile   Read only
         * @param  Object|undefined   oldFile   Read only
         * @return undefined
         */
        inputFile: function (newFile, oldFile) {
            if (newFile && oldFile && !newFile.active && oldFile.active) {
                // Get response data
                console.log('response', newFile.response)
                if (newFile.xhr) {
                    //  Get the response status code
                    console.log('status', newFile.xhr.status)
                }
            }
        },
        /**
         * Pretreatment
         * @param  Object|undefined   newFile   Read and write
         * @param  Object|undefined   oldFile   Read only
         * @param  Function           prevent   Prevent changing
         * @return undefined
         */
        inputFilter: function (newFile, oldFile, prevent) {
            if (newFile && !oldFile) {
                // Filter non-image file
                if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
                    return prevent()
                }
            }

            // Create a blob field
            newFile.blob = ''
            let URL = window.URL || window.webkitURL
            if (URL && URL.createObjectURL) {
                newFile.blob = URL.createObjectURL(newFile.file)
            }
        },
        verLectura(idx_modulo, idx_lectura) {
            let loaderCursoActual = this.$loading.show({ canCancel: false })
            axios
                .get(this.$backendUrl + '/me/curso/avance/?id=' + this.id_curso, { headers: { token: localStorage.userToken } })
                .then(response => {
                    let avanza = false
                    this.lecturaActual = response.data.data.id_curso_modulo_lectura
                    this.lectura = this.cursoActual.modulos[idx_modulo].lecturas[idx_lectura]
                    if (this.lectura.leida > 0) {
                        avanza = true
                    } else {
                        console.log(this.lectura.id_curso_modulo_lectura, this.lecturaActual)
                        if (this.lectura.id_curso_modulo_lectura == this.lecturaActual) {
                            avanza = true
                        }
                    }
                    if (avanza) {
                        let loaderCursoActual = this.$loading.show({ canCancel: false })
                        this.lectura = this.cursoActual.modulos[idx_modulo].lecturas[idx_lectura]
                        let lectura = this.cursoActual.modulos[idx_modulo].lecturas[idx_lectura]
                        this.contenidoVisor = lectura.contenido_hmtl
                        this.nombreVideo = lectura.nombre_video
                        this.pathVideo = lectura.nombreVideo // this.$backendUrl + '/video?path=' + this.cursoActual.identificador + '&id=' + lectura.nombre_video
                        this.tipo = lectura.tipo
                        this.nombreModulo = this.cursoActual.modulos[idx_modulo].nombre_modulo
                        this.nombreLectura = lectura.nombre_lectura
                        this.cuestionario = {}
                        if (this.tipo == 'cuestionario') {
                            axios
                            .get(this.$backendUrl + '/cuestionario/?id=' + this.nombreVideo, { headers: { token: localStorage.userToken } })
                            .then(response => {
                                this.cuestionario = response.data
                            })
                            loaderCursoActual.hide()
                        } else {
                            loaderCursoActual.hide()
                        }
                        
                    } else {
                        this.$swal({ icon: 'error', text: 'Aún no puedes comenzar esta clase' })
                    }
                    loaderCursoActual.hide()
                }, error => {
                    console.log(error.response.data.description)
                })
        },
        onEnd: function () {
            //alert('SE TERMINÓ EL VIDEO')
            //console.log('test on Ended')
            this.lectura.leida = 1
            axios
                .post(this.$backendUrl + '/me/curso/avance', {
                    'id_curso': this.id_curso,
                    'id_curso_modulo_lectura': this.lectura.id_curso_modulo_lectura
                }, { headers: { token: localStorage.userToken } })
                .then(response => {
                    console.log(response)
                }, error => {
                    console.log(error.response.data.description)
                })
    }
    },
}

</script>

<style lang="scss">
.visor {
    background-color: #fff;
    width: 95%;
    padding: 10px 20px;
    border-radius: 10px;
    .btn-completar {
        margin-top: 16px;
    }
    .modulo {
        font-weight: bolder;
        font-size: 16px;
    }
    .temas {
        > .row {
            padding: 5px 5px;
        }
        .contenido {
            padding: 5px 10px;
            .row {
                padding: 8px 15px;
            }
        }
        background-color: #eee;
        span {
            font-size: 14px;
            padding: 3px;
        }
    }
}
</style>
