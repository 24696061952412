<template>
    <div class="account-area">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="account-content">
                        <div class="account-header">
                            <router-link to="/">
                                <img src="../../assets/images/talencer_fondo_pequeno.png" alt="main-logo">
                            </router-link>
                            <h3>Crear una cuenta</h3>
                        </div>

                        <form @submit.prevent="submit" class="account-wrap">
                            <div class="form-group mb-24 icon">
                                <input v-model="form.email" type="email" class="form-control" placeholder="Email">
                                <img src="../../assets/images/icon/user-square.svg" alt="user-square">
                            </div>
                            <div class="form-group mb-24 icon">
                                <input v-model="form.emailConfirm" type="email" class="form-control" placeholder="Confirmar tu email">
                                <img src="../../assets/images/icon/sms.svg" alt="sms">
                            </div>
                            <div class="form-group mb-24 icon">
                                <input v-model="form.password" type="password" class="form-control" placeholder="Contraseña">
                                <img src="../../assets/images/icon/key.svg" alt="key">
                            </div>
                            <div class="form-group mb-24">
                                <div class="form-check">
                                    <input v-model="form.acceptTerms" class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                    <label class="form-check-label" for="flexCheckDefault">
                                        <router-link to="/terminos-y-condiciones">Acepto los terminos y condiciones</router-link>
                                    </label>
                                </div>
                            </div>
                            <div class="form-group mb-24">
                                <button type="submit" class="default-btn">Crear una cuenta</button>
                            </div>
                            <div class="form-group mb-24">
                                <p class="account">Ya tienes una cuenta? <router-link to="/login">Accesa</router-link></p>
                            </div>
                        </form>

                        <ul class="account-social-link">
                            <li>
                                <a href="https://www.google.com/" target="_blank">
                                    <i class='bx bxl-google' ></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.facebook.com/" target="_blank">
                                    <i class='bx bxl-facebook'></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.twitter.com/" target="_blank">
                                    <i class='bx bxl-twitter'></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'RegisterPage',
    data() {
        return {
            form: {
                email: '',
                emailConfirm: '',
                password: '',
                acceptTerms: false,
                activationCode: '',
                id_curso: '',
            }
        }
    },
    mounted() {
        if (!localStorage.webToken) {
            let loader = this.$loading.show({ canCancel: false })
            axios
            .get(this.$backendUrl + '/devices/token?device=' + this.$backendDevice)
            .then(response => {
                console.log(response.data.token)
                localStorage.webToken = response.data.token
                loader.hide()
            })
        }
        if (this.$route.query.wished !== undefined) {
            this.form.id_curso = this.$route.query.wished
        }
    },
    computed: {
        scorePassword() {
            let score = 0;
            if (this.form.password === '') return score;
            let letters = {};
            for (let i = 0; i < this.form.password.length; i++) {
                letters[this.form.password[i]] = (letters[this.form.password[i]] || 0) + 1;
                score += 5.0 / letters[this.form.password[i]];
            }
            let variations = {
                digits: /\d/.test(this.form.password),
                lower: /[a-z]/.test(this.form.password),
                upper: /[A-Z]/.test(this.form.password),
                special: /\W/.test(this.form.password)
            };
            let variationsCount = 0;
            for (let check in variations) {
                variationsCount += (variations[check] === true) ? 1 : 0;
            }
            score += (variationsCount - 1) * 10;
            return parseInt(score);
        }
    },
    methods: {
        submit() {
            if (this.form.email != this.form.emailConfirm) {
                this.$swal({ icon: 'error', text: 'Los correos no coinciden!!!' })
                return false
            }
            if (this.scorePassword < 50) {
                this.$swal({ icon: 'error', text: 'Tu password es muy simple, intenta usar mayúsculas, números y algún signo de admiración' })
                return false
            }
            if (!this.form.acceptTerms) {
                this.$swal({ icon: 'warning', text: 'Debes aceptar los términos y condiciones para crear tu cuenta.' })
                return false
            }
            let loader = this.$loading.show({ canCancel: false })
            axios
            .post(this.$backendUrl + '/accesos/crear', this.form, { headers: { token: localStorage.webToken } })
                .then(response => {
                    if (response.status == 200) {
                        if (response.data.emailEnviado) {
                            loader.hide()
                            this.form.activationCode = response.data.codigoActivacion
                            this.$swal({ icon: 'info', text: 'Revisa tu correo para confirmar tu cuenta.' })
                            this.$router.push('/login')
                        }
                    }
                },
                    error => {
                    loader.hide()
                    this.$swal({ icon: 'error', text: error.response.data.description })
                })
        }
    },
}
</script>

<style lang="scss">
.account-area {
	background-image: url(../../assets/images/account-bg.jpg);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: auto;
	height: 100vh;
}
.account-content {
	max-width: 510px;
	margin: auto;
	background-color: var(--white-color);
	border-radius: 30px;
	padding: 50px;
}
.account-header {
	text-align: center;
	img {
		margin-bottom: 30px;
	}
	h3 {
		margin-bottom: 30px;
		padding-top: 30px;
		border-top: 1px solid #EBF1FF;
		font-size: 20px;
	}
}
.account-wrap {
	.forgot {
		color: #1765FD;
		font-size: 14px;
	}
	.default-btn {
		color: var(--white-color);
		width: 100%;
		padding: 23px 20px;
	}
	.account {
		font-size: 14px;
		margin-bottom: 0;
		color: #292D32;
		a {
			color: #1765FD;
		}
	}
	.form-check {
		a {
			color: #1765FD;
		}
	}
}
.account-social-link {
	padding-left: 0;
	margin-bottom: 0;
	list-style-image: none;
	text-align: center;
	li {
		display: inline-block;
		margin-right: 15px;
		&:last-child {
			margin-right: 0;
		}
		a {
			width: 46px;
			height: 46px;
			line-height: 50px;
			text-align: center;
			display: inline-block;
			background-color: #EBF1FF;
			color: #1765FD;
			border-radius: 50%;
			font-size: 20px;
			&:hover {
				background-color: var(--main-color);
				color: var(--white-color);
			}
		}
	}
}


/* Max width 767px */
@media only screen and (max-width: 767px) {
    .account-area {
        height: 100%;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .account-content {
        padding: 30px;
    }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .account-area {
        height: 100%;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .account-content {
        padding: 30px;
    }
}

/* Min width 1320px to Max width 1550px */
@media only screen and (min-width: 1320px) and (max-width: 1550px) {
    .account-area {
        height: 100%;
        padding-top: 50px;
        padding-bottom: 50px;
    }

}
</style>