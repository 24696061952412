<template>
    <div class="widgets-area">
        <div class="container-fluid">
            <div class="card-box-style">
                <div class="row justify-content-center">
                    <div class="curso-box col-sm-12" v-for="(curso, index) in cursos" :key="index">
                        <div class="card card-box">
                            <div class="card-body">
                                <h5 class="card-title">{{curso.nombre_curso}}</h5>
                                <p><strong>{{curso.descripcion_curso_corta}}</strong></p>
                                <div class="row">
                                    <div class="col-sm-8">
                                        <p class="card-statics">
                                            <span class="badge">{{ curso.modulos }}</span> modulo(s)<br>
                                            <span class="badge">{{ curso.lecturas }}</span> lectura(s)<br>
                                            <span class="badge">{{ curso.lecturas * 0.5 }}</span> horas (aprox)
                                        </p>
                                    </div>
                                    <div class="col-sm-4">
                                        <router-link :to="link(curso.id_curso)" class="btn btn-success">
                                            <i class="bx bx-plus"></i> Inscribirte por ${{ curso.costo }} MXN
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row justify-content-center">

                    <div class="col-lg-3 col-sm-6" v-for="(curso, index) in cursosRecomendado" :key="index">
                        <div class="card card-box border-primary mb-24">
                            <div class="card-header">{{ curso.nombre_curso }}</div>
                            <div class="card-body">
                                <h5 class="card-title">{{ curso.descripcion_curso_corta }}</h5>
                                <p class="card-statics"><span class="badge">{{ curso.modulos }}</span> modulos / <span
                                        class="badge">{{ curso.lecturas }}</span> lecturas</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
//import moment from 'moment-timezone'

export default {
    name: 'CursosGuest',
    data() {
        return {
            cursos: {},
        }
    },
    mounted() {
        if (!localStorage.webToken) {
            axios
                .get(this.$backendUrl + '/devices/token?device=' + this.$backendDevice)
                .then(response => {
                    console.log(response.data.token)
                    localStorage.webToken = response.data.token
                    window.location.reload()
                })
        }
        let loaderCursos = this.$loading.show({ canCancel: false })
        axios
            .get(this.$backendUrl + '/global/cursos?nombre', { headers: { token: localStorage.webToken } })
            .then(response => {
                this.cursos = response.data.data
                loaderCursos.hide()
            }, error => {
                console.log(error.response.data.description)
                loaderCursos.hide()
            })
    },
    methods: {
        link: function (id) {
            return '/visitante/curso?id=' + btoa(id)
        }
    },
}
</script>

<style lang="scss">
.curso-box {
    padding-top: 1em;
}

.card-box {
    h5 {
        font-weight: 500;
        font-size: 20px;
    }
}

.card-statics {
    .badge {
        background-color: #1765fd;
    }
    font-size: 12px;
}
</style>