<template>
    <div class="contact-list-area">
        <div class="row">
            <div class="col-sm-6">
                <button type="button" class="btn btn-success mb-1 me-1" @click="guardarCuestionario()"><i class="ri-save-fill"></i>Guardar Cuestionario</button>
                <button type="button" class="btn btn-outline-danger mb-1 me-1" @click="$router.go(-1)"><i class="bx bxs-no-entry"></i>Cancelar</button>
            </div>
        </div>
        <div class="card-box-style">
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label class="form-check-label">Nombre</label>
                        <input v-model="cuestionario.titulo" type="text" class="form-control" placeholder="Nombre" required>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <label class="form-check-label">Instrucciones Generales</label>
                        <QuillEditor v-model:content="cuestionario.descripcion" contentType="html" theme="snow" toolbar="full" />
                    </div>
                </div>

            </div>
        </div>
        <div class="row" style="padding-bottom: 16px;">
            <div class="col-sm-4">
                <span class="badge text-bg-success me-1" @click="agregarPregunta()"><i class="bx bxs-add-to-queue" style="color:white;"></i> Agregar Pregunta</span>
            </div>
        </div>
        <div v-for="(pregunta, indexPregunta) in cuestionario.preguntas" :key="indexPregunta">
            <div class="card-box-style">
                <div class="row">
                    <div class="col-sm-4">
                        <span class="badge text-bg-danger me-1"><i class="bx bxs-trash" @click="borrarPregunta(indexPregunta)" style="color:white;"></i> Borrar</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label class="form-check-label">Texto de pregunta</label>
                            <QuillEditor v-model:content="pregunta.pregunta" contentType="html" theme="snow" toolbar="full" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="form-check-label">Opción <span @click="agregarOpcion(indexPregunta,opcionId,opcionEditor)">
                                <i v-if="opcionId == -1" class="bx bxs-add-to-queue" style="color: green;"></i>
                                <i v-else class="ri-save-fill" style="color: blue;"></i>
                            </span></label>
                            <QuillEditor v-model:content="opcionEditor" contentType="html" theme="snow" toolbar="full" />
                            <label class="form-check-label">Es la respuesta <input type="checkbox" v-model="opcionAnswer" /></label>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <strong>Opciones:</strong>
                        <div v-for="(respuesta, indexRespuesta) in pregunta.detalle.opciones" :key="indexRespuesta">
                            <div class="row">
                                <div class="col-sm-1">
                                    <i class="bx bxs-trash" @click="borrarOpcion(indexPregunta,indexRespuesta)" style="color:red;"></i>
                                    <span v-if="respuesta.a"><i class="bx bxs-check-square"></i></span>
                                </div>
                                <div class="col-sm-11">
                                    <span @click="editarOpcion(indexRespuesta, respuesta)" v-html="respuesta.t"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import axios from 'axios'

export default {
    name: 'EditorCuestionario',
    components: {
        QuillEditor,
    },
    data() {
        return {
            idCuestionario: 0,
            cuestionario: {
                preguntas: []
            },
            opcionEditor: '<p><br></p>',
            opcionId: -1,
            opcionAnswer: false,
        }
    },
    mounted() {
        if (this.$route.query.id !== undefined) {
            this.idCuestionario = this.$route.query.id
            if (this.idCuestionario > 0) {
                let loader = this.$loading.show({ canCancel: false })
                axios
                    .get(this.$backendUrl + '/cuestionario?id=' + this.idCuestionario, { headers: { token: localStorage.userToken } })
                    .then(response => {
                        if (response.status == 200) {
                            this.cuestionario = response.data
                            loader.hide()
                        }
                    },
                    error => {
                        loader.hide()
                        console.log(error)
                    })
            }
        } else {
            this.idCuestionario = 0
        }
    },
    methods: {
        guardarCuestionario() {
            let loader = this.$loading.show({ canCancel: false })
            axios
            .post(this.$backendUrl + '/cuestionario', this.cuestionario, { headers: { token: localStorage.userToken } })
            .then(response => {
                console.log(response)
                this.$router.go(-1)
                loader.hide()
            },
            error => {
                loader.hide()
                console.log(error)
            })
        },
        agregarPregunta() {
            let pregunta = {
                detalle: { opciones: [] },
                id_cuestionario_pregunta: 0,
                pregunta: '<p><br></p>',
            }
            console.log(pregunta)
            this.cuestionario.preguntas.push(pregunta)
            this.opcionId = -1
            this.opcionEditor = '<p><br></p>'
        },
        agregarOpcion(idPregunta, idOpcion, opcion) {
            console.log(idPregunta, idOpcion, opcion, this.cuestionario.preguntas[idPregunta].detalle.opciones[idOpcion])
            if (idOpcion > -1) {
                this.cuestionario.preguntas[idPregunta].detalle.opciones[idOpcion] = {t:opcion, a:this.opcionAnswer}
            } else {
                this.cuestionario.preguntas[idPregunta].detalle.opciones.push({t:opcion, a:this.opcionAnswer})
            }
            this.opcionId = -1
            this.opcionEditor = '<p><br></p>'
            this.opcionAnswer = false
        },
        editarOpcion(idOpcion, opcion) {
            this.opcionEditor = opcion.t
            this.opcionId = idOpcion
            this.opcionAnswer = opcion.a
        },
        borrarOpcion(idPregunta, idOpcion) {
            delete this.cuestionario.preguntas[idPregunta].detalle.opciones.splice(idOpcion, 1)
            this.opcionAnswer = false
        }
    },
}

</script>

<style lang="scss">
.ql-editor {
    height: 100px !important; 
}

.dp__input_reg {
    height: 40px;
    color: var(--heading-color);
    border: 1px solid #F1F5FF;
    background-color: #F1F5FF;
    border-radius: 10px;
    font-size: 14px;
    padding: 10px 20px;
    width: 100%;
    transition: var(--transition);
    margin-top: -20px;
    margin-left: 20px;
}
.contact-list-area {
    margin-bottom: 24px;

    .table-responsive {
        background-color: var(--white-color);
        box-shadow: var(--box-shadow);
        padding: 30px;
        border-radius: var(--border-radius);
        height: 595px;

        &.scroll-bar {
            overflow-x: scroll !important;

            &::-webkit-scrollbar {
                height: 4px;
            }
        }
    }

    .table {
        tbody {
            tr {
                border-bottom: 1px solid #EBF1FF;
                border-radius: 10px;
                transition: var(--transition);

                &:hover {
                    background-color: #F8FAFF;
                    border-color: var(--white-color);
                }

                td {
                    padding: 20px 20px;
                    border-radius: 10px;

                    .form-check {
                        margin-bottom: 0;

                        .form-check-input {
                            &:focus {
                                box-shadow: none;
                            }
                        }

                        h4 {
                            font-size: 16px;
                            font-weight: 600;
                            margin-bottom: 0;
                        }

                        a {
                            font-size: 12px;
                            color: #67748E;
                        }

                        img {
                            width: 50px;
                        }

                        .border {
                            border: 3px solid var(--white-color) !important;
                            box-shadow: var(--box-shadow);
                        }
                    }

                    a {
                        font-size: 16px;
                        color: #67748E;
                    }

                    .location {
                        color: #67748E;
                        font-size: 16px;
                    }

                    ul {
                        padding-left: 0;
                        margin-bottom: 0;
                        list-style-type: none;
                        float: right;

                        li {
                            display: inline-block;
                            margin-right: 30px;

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .simplebar-scrollbar:before {
        background-color: var(--main-color);
    }

    .simplebar-track.simplebar-horizontal .simplebar-scrollbar {
        height: 30px;
        top: -20px;
        cursor: pointer;
    }

    .form-check .form-check-input {
        border-color: #1765fd;
        background-color: rgba(23, 101, 253, 0.1);
        width: 20px;
        height: 20px;
    }

    .form-check-input:checked[type=checkbox] {
        background-color: #1765fd;
    }
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
    .contact-list-area {
        .table-responsive {
            overflow: auto;

            table {
                tbody {
                    tr {
                        td {
                            white-space: nowrap;
                            padding: 15px !important;
                        }
                    }
                }
            }
        }

        .table tbody {
            tr td ul li {
                width: 20px;
            }
        }
    }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .contact-list-area {
        .table-responsive {
            overflow: auto;

            table {
                tbody {
                    tr {
                        td {
                            white-space: nowrap;
                            padding: 15px !important;

                            .form-check img {
                                width: 30px;
                            }
                        }
                    }
                }
            }
        }

        .table tbody {
            tr td ul li {
                width: 20px;
            }
        }
    }
}
</style>