<template>
    <div class="widgets-area">
        <div class="container-fluid">
            <div class="row">
                <div class="card-box-style">
                    <div class="row justify-content-center">
                        <h6>Cancelaste la inscripción al curso</h6>
                        <p><strong>{{ identificador }} - {{nombreCurso}}</strong>.</p>
                        <router-link :to="link(id_curso)" class="btn btn-success">
                            <i class="bx bx-money"></i> Haz clic aquí para intentar de nuevo
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
//import moment from 'moment-timezone'

export default {
    name: 'CheckoutCancelContent',
    data() {
        return {
            paymentData: {},
            cursoActual: {},
            nombreCurso: '',
            identificador: '',
            descripcionCorta: '',
            cupon: '',
            amount: 0,
            id_curso: 0,
            cuponAplicado: false,
            montoDescuento: 0,
            costoCurso: 1500,
        }
    },
    mounted() {
        if (this.$route.query.data !== undefined) {
            localStorage.lastCheckoutId = ''
            let query = atob(this.$route.query.data)
            let dataPayment = query.split('|')
            let loaderCurso = this.$loading.show({ canCancel: false })
            this.id_curso = dataPayment[0]
            axios
                .get(this.$backendUrl + '/global/curso?id=' + this.id_curso, { headers: { token: localStorage.userToken } })
                .then(response => {
                    this.cursoActual = response.data.data
                    this.nombreCurso = this.cursoActual.nombre_curso
                    this.identificador = this.cursoActual.identificador
                    this.descripcionCorta = this.cursoActual.descripcion_curso_corta
                    this.amount = this.costoCurso
                    loaderCurso.hide()
                }, error => {
                    console.log(error.response.data.description)
                    loaderCurso.hide()
                })
        }
    },
    methods: {
        link: function (id) {
            return '/cursos/previo?id=' + btoa(id)
        },
    },
}
</script>

<style lang="scss">
.curso-box {
    padding-top: 1em;
}

.card-box {
    h5 {
        font-weight: 500;
        font-size: 20px;
    }
}

.card-statics {
    .badge {
        background-color: #1765fd;
    }
    font-size: 12px;
}
</style>