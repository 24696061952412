<template>
    <div class="all-section-area">
        <MainHeaderGuest />
        <main class="main-content-wrap">
            <PrevioCursoGuest />
            <FooterAreaGuest />
        </main>
    </div>
</template>

<script>
import MainHeaderGuest from '../Layouts/MainHeaderGuest'
import PrevioCursoGuest from '../Cursos/PrevioCursoGuest'
import FooterAreaGuest from '../Layouts/FooterAreaGuest'

export default {
    name: 'PrevioCursoGuestPage',
    components: {
        MainHeaderGuest,
        PrevioCursoGuest,
        FooterAreaGuest,
    },
    mounted() {
        document.body.classList.add('body-bg-f8faff')
    },
}
</script>

<style lang="scss">
.main-content-wrap {
    padding-top: 80px !important;
    padding-left: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
}
</style>
