<template>
    <div class="footer-area">
        <div class="container-fluid">
            <div class="footer-content">
                <p>© Talencer - <a href="https://sincco.com/" target="_blank">Sincco</a></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterArea'
}
</script>

<style lang="scss">
.footer-content {
	text-align: center;
	background: #00213d 0% 0% no-repeat padding-box;
	border: 1px solid #CAD6F2;
	border-radius: 20px;
	padding: 20px;
	margin-bottom: 12px;
	p {
		font-size: 14px;
		font-weight: 600;
		color: #fff;
		a {
			color: #9ed2ff;
		}
	}
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
	.footer-content {
        padding: 15px;
        border-radius: 10px;
        p {
            font-size: 13px;
        }
    }
}
</style>