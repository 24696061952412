<template>
    <div class="account-area">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="account-content">
                        <div class="account-header">
                            <router-link to="/">
                                <img src="../../assets/images/talencer_fondo_pequeno.png" alt="main-logo">
                            </router-link>
                            <h3>Accesar</h3>
                        </div>

                        <form @submit.prevent="submit" class="account-wrap">
                            <div class="form-group mb-24 icon">
                                <input v-model="form.email" type="email" class="form-control" placeholder="Email" required>
                                <img src="../../assets/images/icon/sms.svg" alt="sms">
                            </div>
                            <div class="form-group mb-24 icon">
                                <input v-model="form.password" type="password" class="form-control" placeholder="Contraseña" required>
                                <img src="../../assets/images/icon/key.svg" alt="key">
                            </div>
                            <div class="form-group mb-24">
                                <div class="form-check">
                                    <input v-model="form.rememberMe" class="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked>
                                    <label class="form-check-label" for="flexCheckDefault">
                                        Mantenerme conectado
                                    </label>
                                </div>
                            </div>
                            <div class="form-group mb-24">
                                <router-link to="/recuperar-password" class="forgot">Olvidaste tu contraseña?</router-link>
                            </div>
                            <div class="form-group mb-24">
                                <button type="submit" class="default-btn">Accesar</button>
                            </div>
                            <div class="form-group mb-24 text-center">
                                <p class="account">No eres un miembro? <router-link to="/registro">Crea una nueva cuenta</router-link></p>
                            </div>
                        </form>
                        <!--
                        <ul class="account-social-link">
                            <li>
                                <a href="https://www.google.com/" target="_blank">
                                    <i class='bx bxl-google' ></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.facebook.com/" target="_blank">
                                    <i class='bx bxl-facebook'></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.twitter.com/" target="_blank">
                                    <i class='bx bxl-twitter'></i>
                                </a>
                            </li>
                        </ul>
                        -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'LoginPage',
    data() {
        return {
            form: {
                email: '',
                password: '',
                rememberMe: true
            }
        }
    },
    mounted() {
        document.body.classList.add('body-bg-f8faff')
        if (!localStorage.webToken) {
            axios
                .get(this.$backendUrl + '/devices/token?device=' + this.$backendDevice)
                .then(response => {
                    console.log(response.data.token)
                    localStorage.webToken = response.data.token
                })
        }
        if (localStorage.userRememberMe) {
            axios
                .post(this.$backendUrl + '/accesos/token', { token: localStorage.userToken }, { headers: { token: localStorage.webToken } })
                .then(response => {
                    console.log(response.data.token)
                    localStorage.user = JSON.stringify(response.data.accesos)
                    localStorage.userRememberMe = this.form.rememberMe
                    this.$router.push('/perfil')
                })
        }
    },
    methods: {
        submit() {
            let loader = this.$loading.show({ canCancel: false })
            axios
                .post(this.$backendUrl + '/accesos/login', this.form, { headers: { token: localStorage.webToken } })
                .then(response => {
                    if (response.status == 200) {
                        localStorage.userToken = response.data.accesos.token
                        localStorage.user = JSON.stringify(response.data.accesos)
                        localStorage.userRememberMe = this.form.rememberMe
                        loader.hide()
                        this.$router.push('/perfil')
                    }
                },
                error => {
                    loader.hide()
                    if (error.response.status == 401) {
                        console.log(error.response.data.codigoActivacion)
                        this.$swal({ icon: 'info', text: error.response.data.description })
                    } else {
                        if (error.response.data.token == false) {
                            axios
                            .get(this.$backendUrl + '/devices/token?device=' + this.$backendDevice)
                            .then(response => {
                                //console.log(response.data.token)
                                localStorage.webToken = response.data.token
                            })
                            this.$swal({ icon: 'error', text: 'Tuvimos problemas para conectar, intenta de nuevo' })
                        } else {
                            this.$swal({ icon: 'error', text: error.response.data.description })
                        }
                    }
                }) 
        },
    },
}
</script>

<style lang="scss">
.account-area {
	background-image: url(../../assets/images/account-bg.jpg);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: auto;
	height: 100vh;
}
.account-content {
	max-width: 510px;
	margin: auto;
	background-color: var(--white-color);
	border-radius: 30px;
	padding: 50px;
}
.account-header {
	text-align: center;
	img {
		margin-bottom: 30px;
	}
	h3 {
		margin-bottom: 30px;
		padding-top: 30px;
		border-top: 1px solid #EBF1FF;
		font-size: 20px;
	}
}
.account-wrap {
	.forgot {
		color: #1765FD;
		font-size: 14px;
	}
	.default-btn {
		color: var(--white-color);
		width: 100%;
		padding: 23px 20px;
        background-color: #292D32;
	}
    .default-btn:hover {
        background-color: #727272;
	}
	.account {
		font-size: 14px;
		margin-bottom: 0;
		color: #292D32;
		a {
			color: #1765FD;
		}
	}
	.form-check {
		a {
			color: #1765FD;
		}
	}
}
.account-social-link {
	padding-left: 0;
	margin-bottom: 0;
	list-style-image: none;
	text-align: center;
	li {
		display: inline-block;
		margin-right: 15px;
		&:last-child {
			margin-right: 0;
		}
		a {
			width: 46px;
			height: 46px;
			line-height: 50px;
			text-align: center;
			display: inline-block;
			background-color: #EBF1FF;
			color: #1765FD;
			border-radius: 50%;
			font-size: 20px;
			&:hover {
				background-color: var(--main-color);
				color: var(--white-color);
			}
		}
	}
}

.form-control {
    background-color: #d5d5d5;
}

label {
    color: #292D32;
}

.form-check-input:checked {
    background-color: #292D32 !important;
    border-color: #292D32 !important;
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
    .account-area {
        height: 100%;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .account-content {
        padding: 30px;
    }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .account-area {
        height: 100%;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .account-content {
        padding: 30px;
    }
}

/* Min width 1320px to Max width 1550px */
@media only screen and (min-width: 1320px) and (max-width: 1550px) {
    .account-area {
        height: 100%;
        padding-top: 50px;
        padding-bottom: 50px;
    }

}
</style>