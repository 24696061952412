<template>
    <div class="row">
        <h3>{{ cursoActual.nombre_curso }} <span style="font-size: 0.5em;">Vista previa</span></h3>
    </div>
    <div class="visor" style="width: 95%;">
        <div class="row">
            <div class="col-sm-12 col-md-8">
                <div class="row right-bar">
                    <h4 v-if="nombreLectura">{{ nombreModulo }} :: <span style="font-size: 0.8em;">{{ nombreLectura
                            }}</span></h4>

                    <div v-if="tipo == 'video'">
                        <div v-if="nombreVideo !== null" class="col-sm-12">
                            <div v-html="nombreVideo"></div>
                            <!--<div style="position:relative;padding-top:56.25%;"><iframe src="https://iframe.mediadelivery.net/embed/328406/49d8a42c-81eb-49d3-8366-414ef715b180?autoplay=true&loop=false&muted=false&preload=true&responsive=true" loading="lazy" style="border:0;position:absolute;top:0;height:100%;width:100%;" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowfullscreen="true" id="bunny"></iframe></div>-->
                            <div v-if="contenidoVisor !== null" v-html="contenidoVisor"></div>
                        </div>
                    </div>
                    
                    <div v-if="tipo == 'html'">
                        <div v-if="contenidoVisor !== null" v-html="contenidoVisor"></div>
                    </div>

                    <div class="cuestionario" v-if="tipo == 'cuestionario'">
                        <h6>{{cuestionario.titulo}}</h6>
                        <div v-html="cuestionario.descripcion"></div>
                        <div class="pregunta" v-for="(pregunta, indexPregunta) in cuestionario.preguntas" :key="indexPregunta">
                            <div v-html="pregunta.pregunta"></div>
                            <div class="respuesta" v-for="(respuesta, indexRespuesta) in pregunta.detalle.opciones" :key="indexRespuesta">
                                {{ respuesta }}
                            </div>
                        </div>
                    </div>

                    <div v-if="contenidoVisor !== null" v-html="contenidoVisor"></div>
                    <button v-if="nombreLectura" type="button" class="btn btn-success mb-1 me-1" disabled>Marcar como
                        completada</button>
                </div>
            </div>
            <div class="col-sm-12 col-md-4 temas">
                <div class="row">
                    <h6>Temas</h6>
                </div>

                <div class="accordion" id="accordionExample">
                    <div class="accordion-item" v-for="(modulo, indexModulo) in cursoActual.modulos" :key="indexModulo">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                :data-bs-target="idAcorddionTarget(indexModulo)" aria-expanded="true"
                                :aria-controls="idAcorddion(indexModulo)">
                                {{ modulo.nombre_modulo }}
                            </button>
                        </h2>
                        <div :id="idAcorddion(indexModulo)" class="accordion-collapse collapse show"
                            aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <div class="row" v-for="(lectura, indexLectura) in modulo.lecturas" :key="indexLectura"
                                    @click="verLectura(indexModulo, indexLectura)">
                                    <div class="col-sm-1">
                                        <div class="checkbox">
                                            <input class="form-check-input" type="checkbox" :checked="lectura.leida > 0"
                                                disabled>
                                        </div>
                                    </div>
                                    <div class="col-sm-11">
                                        <span>{{ lectura.nombre_lectura }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios'

export default {
    name: 'VisorCurso',
    data() {
        return {
            id_curso: 0,
            cursoActual: {},
            contenidoVisor: null,
            nombreVideo: null,
            nombreLectura: null,
            nombreModulo: null,
            pathVideo: null,
            tipo: '',
            lectura: {},
            lecturaActual: 0,
            indexModuloActual: 0,
            indexLecturaActual: 0,
            files: [],
            cuestionario: {},
        }
    },
    mounted() {
        let loaderCursoActual = this.$loading.show({ canCancel: false })
        if (this.$route.query.id !== undefined) {
            this.id_curso = this.$route.query.id
        }
        axios
            .get(this.$backendUrl + '/admin/curso?id=' + this.id_curso, { headers: { token: localStorage.userToken } })
            .then(response => {
                this.cursoActual = response.data
                loaderCursoActual.hide()
            }, error => {
                console.log(error.response.data.description)
                loaderCursoActual.hide()
            })
    },
    methods: {
        idAcorddion(id) {
            return 'modulo' + id;
        },
        idAcorddionTarget(id) {
            return '#modulo' + id;
        },
        verLectura(idx_modulo, idx_lectura) {
            let loaderCursoActual = this.$loading.show({ canCancel: false })
            this.lectura = this.cursoActual.modulos[idx_modulo].lecturas[idx_lectura]
            let lectura = this.cursoActual.modulos[idx_modulo].lecturas[idx_lectura]
            this.contenidoVisor = lectura.contenido_hmtl
            this.nombreVideo = lectura.nombre_video
            this.pathVideo = lectura.nombre_video + '?autoplay=true&loop=false&muted=false&preload=true&responsive=true' // this.$backendUrl + '/video?path=' + this.cursoActual.identificador + '&id=' + lectura.nombre_video
            this.tipo = lectura.tipo
            this.nombreModulo = this.cursoActual.modulos[idx_modulo].nombre_modulo
            this.nombreLectura = lectura.nombre_lectura
            this.cuestionario = {}
            if (this.tipo == 'cuestionario') {
                axios
                .get(this.$backendUrl + '/cuestionario/?id=' + this.nombreVideo, { headers: { token: localStorage.userToken } })
                .then(response => {
                    this.cuestionario = response.data
                })
                loaderCursoActual.hide()
            } else {
                loaderCursoActual.hide()
            }
        }
    }
}

</script>

<style lang="scss">
.visor {
    background-color: #fff;
    width: 95%;
    padding: 10px 20px;
    border-radius: 10px;
    .modulo {
        font-weight: bolder;
        font-size: 16px;
    }
    .temas {
        > .row {
            padding: 5px 5px;
        }
        .contenido {
            padding: 5px 10px;
            .row {
                padding: 8px 15px;
            }
        }
        background-color: #eee;
        span {
            font-size: 14px;
            padding: 3px;
        }
    }
}
</style>
