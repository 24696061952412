import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import BootstrapVue3 from "bootstrap-vue-3"
import VueApexCharts from "vue3-apexcharts"
import { createGtm } from '@gtm-support/vue-gtm'

import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-vue-3/dist/bootstrap-vue-3.css"

import "./assets/custom.scss"

import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import {LoadingPlugin} from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/css/index.css'
import './registerServiceWorker'

import VueCountdown from '@chenfengyuan/vue-countdown';


const app = createApp(App).use(router)
app.use(VueApexCharts)
app.use(BootstrapVue3)
app.use(VueSweetalert2)
app.use(LoadingPlugin, {loader:process.env.VUE_APP_GENERAL_LOADER_TYPE, canCancel: false, color: process.env.VUE_APP_GENERAL_LOADER_COLOR})
app.component(VueCountdown.name, VueCountdown);

app.config.globalProperties.$backendUrl = process.env.VUE_APP_GENERAL_BACKEND_URL
app.config.globalProperties.$backendDevice = process.env.VUE_APP_GENERAL_DEVICE_ID

app.use(
  createGtm({
    id: process.env.VUE_APP_WEB_GTM_ID, // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
    compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: true, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    ignoredViews: ['homepage'], // Don't trigger events for specified router names (optional)
    trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
  }),
)

app.mount("#app")
